'use client'
import EmailOptInModal from './EmailOptInModal'
import { setIsCrisisModalOpen, setIsEmailModalOpen } from './modalSlice'
import { connect } from 'react-redux'
import { prop } from 'ramda'

const mapDispatchToProps = {
  setIsCrisisModalOpen,
  setIsEmailModalOpen,
}

const mapStateToProps = ({ modal, user }) => ({
  emailOptIn: prop('email_opt_in', user),
  emailOptInPendingUpdate: prop('email_opt_in_pending_update', user),
  hasBeenShownAModal: prop('has_been_shown_a_modal', user),
  id: prop('id', user),
  isOpen: prop('isEmailModalOpen', modal),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailOptInModal)
