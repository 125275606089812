import { equals, isNil, not, path } from 'ramda'
import { useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import en from 'assets/lang/en.json'
import es from 'assets/lang/es.json'

const LanguageHandler = ({ children }) => {
  const preferredLocale = useSelector(path(['user', 'preferred_locale']))

  const messages = useMemo(() => {
    if (isNil(preferredLocale)) {
      return en
    }
    if (not(isNil(preferredLocale))) {
      return equals('en', preferredLocale) ? en : es
    }
  }, [preferredLocale])

  return (
    <IntlProvider locale={preferredLocale ?? 'en'} messages={messages}>
      {children}
    </IntlProvider>
  )
}

LanguageHandler.propTypes = {
  children: PropTypes.node.isRequired
}

export default LanguageHandler
