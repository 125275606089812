import { Button, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const NotFound = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    m="25% auto"
    spacing={2}
    width="100%"
  >
    <Typography variant="h1">404</Typography>
    <Typography variant="body1">
      <FormattedMessage
        defaultMessage="What happened? Let's go back."
        id="uc9A60"
      />
    </Typography>
    <Button href="/" LinkComponent={Link}>
      <FormattedMessage defaultMessage="Go Home" id="lr13V0" />
    </Button>
  </Stack>
)

export default NotFound
