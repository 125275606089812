import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  isCrisisModalOpen: false,
  isEmailModalOpen: false,
}

const modalSlice = createSlice({
  initialState,
  name: 'modal',
  reducers: {
    setIsCrisisModalOpen: (state, { payload }) =>
      assoc('isCrisisModalOpen', payload, state),
    setIsEmailModalOpen: (state, { payload }) =>
      assoc('isEmailModalOpen', payload, state),
  },
})

export const { setIsCrisisModalOpen, setIsEmailModalOpen } = modalSlice.actions

export default modalSlice.reducer
