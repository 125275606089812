import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const ScreenReaderMessage = ({ children }) => (
  <Box
    className="screen-reader-only"
    component="span"
    sx={{
      WebkitClipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
      clip: 'rect(1px,1px,1px,1px)',
      clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
      height: 0,
      overflow: 'hidden',
      position: 'absolute',
      width: 0,
    }}
  >
    {children}
  </Box>
)

export default ScreenReaderMessage

ScreenReaderMessage.propTypes = {
  children: PropTypes.any.isRequired,
}
