'use client'
import CrisisModal from './CrisisModal'
import { setIsCrisisModalOpen } from './modalSlice'
import { connect } from 'react-redux'
import { prop } from 'ramda'

const mapDispatchToProps = {
  setIsCrisisModalOpen,
}

const mapStateToProps = ({ modal }) => ({
  isOpen: prop('isCrisisModalOpen', modal),
})

export default connect(mapStateToProps, mapDispatchToProps)(CrisisModal)
