import { isNil, or } from 'ramda'

export const getTakeActionSubtitle = (takeActionCard, intl) => {
  if (takeActionCard.active_goal) {
    return intl.formatMessage({
      // eslint-disable-next-line quotes
      defaultMessage: "Revisit a goal you've set",
      id: 'oeAio+',
    })
  } else if (takeActionCard.poll_question) {
    return intl.formatMessage({
      defaultMessage: 'Spend a few minutes checking in',
      id: 'PTAASs',
    })
  }
  return intl.formatMessage({
    defaultMessage: 'Because you prioritized',
    id: 'tIfuZd',
  })
}

export const getTakeActionLinkInfo = (subdomain, card, intl) => {
  if (or(isNil(card), isNil(subdomain))) return
  // if goal we want to link to my-goals
  // if poll question we want to link to self checks
  // else it's a skill and we want to use subdomain info
  if (card.active_goal) {
    return {
      href: '/goals/my-goals',
      linkText: intl.formatMessage({
        // eslint-disable-next-line quotes
        defaultMessage: 'See my goals',
        id: 'tIYVU+',
      }),
    }
  } else if (card.poll_question) {
    return {
      href: '/self-checks',
      linkText: intl.formatMessage({
        defaultMessage: 'Take a self check',
        id: 'cQG0/p',
      }),
    }
  }
  return {
    href: subdomain.link,
    linkText: subdomain.title,
  }
}
