import SSOSignUpForm from './SSOSignUpForm'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { resetState } from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  consent: prop('consent', authentication),
  location: prop('location', authentication),
  role: prop('role', authentication),
})

export default connect(mapStateToProps, { resetState })(SSOSignUpForm)
