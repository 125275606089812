import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { defaultTo, isNil, not } from 'ramda'

const VideoImage = ({
  ariaLabel = 'video, to play press the space bar',
  background = null,
  handleGA = null,
  handleKeen,
  videoUrl,
}) => (
  <Box
    aria-label={ariaLabel}
    component="video"
    controls
    controlsList="nodownload"
    height="100%"
    onPause={() => handleKeen('Video Pause', 'Button')}
    onPlay={() => {
      not(isNil(handleGA)) && handleGA()
      handleKeen('Video Play', 'Button')
    }}
    playsInline
    poster={defaultTo('', background)}
    src={videoUrl}
    sx={{
      borderRadius: 2,
      maxHeight: 393,
    }}
    width="100%"
  />
)

VideoImage.propTypes = {
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  background: PropTypes.string,
  handleGA: PropTypes.func,
  handleKeen: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
}

export default VideoImage
