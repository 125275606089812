import { prop } from 'ramda'

/* eslint-disable sort-keys */
export const convertCardTypeToString = typeId => {
  /**
   * The card types. These match the constants in Card.php
   * on the BE as of 4/13/2021. They will need to be manually
   * kept in lockstep going forward.
   *
   * This is not a good way to do this but we dont have time
   * to do a more elegant fix in this sprint.
   *
   * Stan
   */
  const types = {
    1: 'Assessment',
    2: 'Resource',
    3: 'Fact/Tip',
    4: 'Life Lesson',
    5: 'Tool',
    6: 'Event',
    7: 'Story',
    8: 'Campus Goal',
    9: 'Challenge',
    10: 'Triage Resource',
    11: 'Geo-Located Resource',
    12: 'Tips',
    13: 'Skills',
  }

  return prop(typeId, types)
}
