import UserTypeSelect from './UserTypeSelect'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { setUserType } from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  userType: prop('userType', authentication),
})

const mapDispatchToProps = {
  setUserType,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTypeSelect)
