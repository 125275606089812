import { Box, Grow } from '@mui/material'
import PropTypes from 'prop-types'

const CardAnimationWrapper = ({ children }) => (
  <Grow in>
    <Box>{children}</Box>
  </Grow>
)

CardAnimationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardAnimationWrapper
