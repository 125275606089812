import { all, equals, gt, isNil, length, not, prop, propEq } from 'ramda'
import { useSelector } from 'react-redux'

const useGetSchoolName = locationId => {
  const { letters, locations, show_locations_in_profile } = useSelector(
    prop('school')
  )

  if (
    all(equals(true))([
      show_locations_in_profile,
      gt(length(locations), 0),
      not(isNil(locationId)),
    ])
  ) {
    const location = locations.find(propEq(locationId, 'id'))
    return prop('name', location)
  }

  return letters
}

export default useGetSchoolName
